<template>
  <div>
    <div class="striping-editor">
      <!-- <header class="striping-editor-header"></header> -->
      <section class="striping-editor-body">
        <ModelViewer
          class="model-viewer-container"
          :model-type="type"
          :colors="gradeStripes"
          :special-coupling="specialCoupling"
          :config="{gui: false, controls: true}" />

        <div class="striping-editor-tools">
          <!-- tube and coupling fields -->
          <div v-if="type === 'TubeAndCoupling'">
            <div class="column">
              <b-field
                :label="`Premium Tube &amp; Coupling`"
                style="font-size: 30px; line-height: 1.2" />
              <b-field label="Coupling" style="font-size: 24px" />
              <b-field horizontal label="Field">
                <b-select v-model="gradeStripes.tncField" class="b-select" expanded>
                  <option
                    v-for="(color, index) in stripeColors"
                    :key="index"
                    :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field horizontal label="Stripe1">
                <b-select
                  v-model="gradeStripes.tncCouplingStripe1"
                  class="b-select"
                  expanded>
                  <option
                    v-for="(color, index) in stripeColors"
                    :key="index"
                    :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                horizontal
                label="Stripe2">
                <b-select
                  v-model="gradeStripes.tncCouplingStripe2"
                  class="b-select"
                  expanded>
                  <option
                    v-for="(color, index) in stripeColors"
                    :key="index"
                    :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                horizontal
                label="Stripe3">
                <b-select
                  v-model="gradeStripes.tncCouplingStripe3"
                  class="b-select"
                  expanded>
                  <option
                    v-for="(color, index) in stripeColors"
                    :key="index"
                    :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Tube" style="font-size: 24px" />
              <b-field horizontal label="Stripe1">
                <b-select
                  v-model="gradeStripes.tncTubeStripe1"
                  class="b-select"
                  expanded>
                  <option
                    v-for="(color, index) in stripeColors"
                    :key="index"
                    :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                horizontal
                label="Stripe2">
                <b-select
                  v-model="gradeStripes.tncTubeStripe2"
                  class="b-select"
                  expanded>
                  <option
                    v-for="(color, index) in stripeColors"
                    :key="index"
                    :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                horizontal
                label="Stripe3">
                <b-select
                  v-model="gradeStripes.tncTubeStripe3"
                  class="b-select"
                  expanded>
                  <option
                    v-for="(color, index) in stripeColors"
                    :key="index"
                    :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>

          <!-- semi-premium tube and coupling fields -->
          <div v-if="type === 'SemiPremiumTubeAndCoupling'">
            <div class="column">
              <b-field
                :label="`Semi-Premium Tube &amp; Coupling`"
                style="font-size: 30px; line-height: 1.2" />
              <b-field label="Coupling" style="font-size: 24px" />
              <b-field horizontal label="Field">
                <b-select v-model="gradeStripes.sptncField" class="b-select" expanded>
                  <option
                    v-for="(color, index) in stripeColors"
                    :key="index"
                    :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field horizontal label="Stripe1">
                <b-select
                  v-model="gradeStripes.sptncCouplingStripe1"
                  class="b-select"
                  expanded>
                  <option
                    v-for="(color, index) in stripeColors"
                    :key="index"
                    :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                horizontal
                label="Stripe2">
                <b-select
                  v-model="gradeStripes.sptncCouplingStripe2"
                  class="b-select"
                  expanded>
                  <option
                    v-for="(color, index) in stripeColors"
                    :key="index"
                    :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                horizontal
                label="Stripe3">
                <b-select
                  v-model="gradeStripes.sptncCouplingStripe3"
                  class="b-select"
                  expanded>
                  <option
                    v-for="(color, index) in stripeColors"
                    :key="index"
                    :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Tube" style="font-size: 24px" />
              <b-field horizontal label="Stripe1">
                <b-select
                  v-model="gradeStripes.sptncTubeStripe1"
                  class="b-select"
                  expanded>
                  <option
                    v-for="(color, index) in stripeColors"
                    :key="index"
                    :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                horizontal
                label="Stripe2">
                <b-select
                  v-model="gradeStripes.sptncTubeStripe2"
                  class="b-select"
                  expanded>
                  <option
                    v-for="(color, index) in stripeColors"
                    :key="index"
                    :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                horizontal
                label="Stripe3">
                <b-select
                  v-model="gradeStripes.sptncTubeStripe3"
                  class="b-select"
                  expanded>
                  <option
                    v-for="(color, index) in stripeColors"
                    :key="index"
                    :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>

          <!-- plain end fields -->
          <div v-if="type === 'PlainEnd'" class="column">
            <b-field :label="`Plain End`" style="font-size: 30px; line-height: 1.2" />
            <b-field horizontal label="Stripe1">
              <b-select
                v-model="gradeStripes.plainendStripe1"
                class="b-select"
                expanded>
                <option
                  v-for="(color, index) in stripeColors"
                  :key="index"
                  :value="color.value">
                  {{ color.name }}
                </option>
              </b-select>
            </b-field>
            <b-field
              horizontal
              label="Stripe2">
              <b-select
                v-model="gradeStripes.plainendStripe2"
                class="b-select"
                expanded>
                <option
                  v-for="(color, index) in stripeColors"
                  :key="index"
                  :value="color.value">
                  {{ color.name }}
                </option>
              </b-select>
            </b-field>
            <b-field
              horizontal
              label="Stripe3">
              <b-select
                v-model="gradeStripes.plainendStripe3"
                class="b-select"
                expanded>
                <option
                  v-for="(color, index) in stripeColors"
                  :key="index"
                  :value="color.value">
                  {{ color.name }}
                </option>
              </b-select>
            </b-field>
            <b-field
              horizontal
              label="Stripe4">
              <b-select
                v-model="gradeStripes.plainendStripe4"
                class="b-select"
                expanded>
                <option
                  v-for="(color, index) in stripeColors"
                  :key="index"
                  :value="color.value">
                  {{ color.name }}
                </option>
              </b-select>
            </b-field>
          </div>

          <!-- semi flush fields -->
          <div v-if="type === 'SemiFlush'">
            <div class="column">
              <b-field :label="`Semi Flush`" style="font-size: 30px; line-height: 1.2" />
              <b-field horizontal label="Stripe1">
                <b-select v-model="gradeStripes.semiflushStripe1" class="b-select" expanded>
                  <option v-for="(color, index) in stripeColors" :key="index" :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field horizontal label="Stripe2">
                <b-select v-model="gradeStripes.semiflushStripe2" class="b-select" expanded>
                  <option v-for="(color, index) in stripeColors" :key="index" :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field horizontal label="Stripe3">
                <b-select v-model="gradeStripes.semiflushStripe3" class="b-select" expanded>
                  <option v-for="(color, index) in stripeColors" :key="index" :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field horizontal label="Stripe4">
                <b-select v-model="gradeStripes.semiflushStripe4" class="b-select" expanded>
                  <option v-for="(color, index) in stripeColors" :key="index" :value="color.value">
                    {{ color.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import ModelViewer from '@/components/misc/ModelViewer'

export default {
  name: 'StripingEditor3D',
  components: {
    ModelViewer
  },
  props: {
    gradeStripes: {
      required: true,
      type: Object
    },
    type: {
      required: true,
      type: String
    },
    material: {
      required: true,
      type: String
    },
    specialCoupling: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      stripeColors: [
        {
          name: 'None',
          value: 'none'
        },
        {
          name: 'White',
          value: 'white'
        },
        {
          name: 'Orange',
          value: '#EF6534'
        },
        {
          name: 'Yellow',
          value: '#F0C430'
        },
        {
          name: 'Brown',
          value: '#833D16'
        },
        {
          name: 'Green',
          value: '#53B150'
        },
        {
          name: 'Blue',
          value: 'blue'
        },
        {
          name: 'Pink',
          value: '#EC509A'
        },
        {
          name: 'Purple',
          value: '#9C55FC'
        },
        {
          name: 'Red',
          value: 'red'
        },
        {
          name: 'Black',
          value: 'black'
        },
        {
          name: 'Gray',
          value: '#D8D8D8'
        }
      ]
    }
  },
  mounted () {},
  methods: {
    async save () {
      // no actual db actions here - that happens at material page
      const loadingComponent = this.$buefy.loading.open({ container: null })
      // this.$emit('change', newGradeStripes)
      loadingComponent.close()

      // this.$parent.close()
    }
  }
}
</script>

<style lang="scss" scoped>
.model-viewer-container {
  height: 450px;
  border-radius: 3px;
  overflow: hidden;
}

.b-select {
  z-index: 0;
  position: relative;
}
</style>
