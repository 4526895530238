<template>
  <div>
    <div v-if="type === 'TubeAndCoupling'">
      <div class="pipe">
        <div class="inner" :style="gradeStripes.tncField != 'none' ? 'background-color:' + gradeStripes.tncField : ''">
          <div v-if="gradeStripes.tncCouplingStripe1 != 'none'" class="stripe" :style="{'background-color': gradeStripes.tncCouplingStripe1}" />
          <div v-if="gradeStripes.tncCouplingStripe2 != 'none'" class="stripe" :style="{'background-color': gradeStripes.tncCouplingStripe2}" />
          <div v-if="gradeStripes.tncCouplingStripe3 != 'none'" class="stripe" :style="{'background-color': gradeStripes.tncCouplingStripe3}" />
          <div v-if="specialCoupling" class="stripe" :style="{'background-color': 'black'}" />
        </div>
        <div class="inner tube">
          <div v-if="gradeStripes.tncTubeStripe1 != 'none'" class="stripe" :style="{'background-color': gradeStripes.tncTubeStripe1}" />
          <div v-if="gradeStripes.tncTubeStripe2 != 'none'" class="stripe" :style="{'background-color': gradeStripes.tncTubeStripe2}" />
          <div v-if="gradeStripes.tncTubeStripe3 != 'none'" class="stripe" :style="{'background-color': gradeStripes.tncTubeStripe3}" />
        </div>
      </div>
    </div>

    <div v-if="type === 'PlainEnd'">
      <div class="pipe">
        <div class="inner">
          <div v-if="gradeStripes.plainendStripe1 != 'none'" class="stripe" :style="{'background-color': gradeStripes.plainendStripe1}" />
          <div v-if="gradeStripes.plainendStripe2 != 'none'" class="stripe" :style="{'background-color': gradeStripes.plainendStripe2}" />
          <div v-if="gradeStripes.plainendStripe3 != 'none'" class="stripe" :style="{'background-color': gradeStripes.plainendStripe3}" />
          <div v-if="gradeStripes.plainendStripe4 != 'none'" class="stripe" :style="{'background-color': gradeStripes.plainendStripe4}" />
        </div>
      </div>
    </div>

    <div v-if="type === 'SemiFlush'">
      <div id="semiflush" class="pipe">
        <div class="inner left" />
        <div class="inner center">
          <svg viewbox="0 0 100 100" preserveAspectRatio="none" width="100%" height="100%">
            <path d="M0,0 L100,20 L100,80 L0,100" fill="#D8D8D8" />
            <polyline points="0,0 100,20 100,80 0,100" fill="#D8D8D8" stroke="#000" stroke-width="1px" />
          </svg>
        </div>
        <div class="inner right">
          <div v-if="gradeStripes.semiflushStripe1 != 'none'" class="stripe" :style="{'background-color': gradeStripes.semiflushStripe1}" />
          <div v-if="gradeStripes.semiflushStripe2 != 'none'" class="stripe" :style="{'background-color': gradeStripes.semiflushStripe2}" />
          <div v-if="gradeStripes.semiflushStripe3 != 'none'" class="stripe" :style="{'background-color': gradeStripes.semiflushStripe3}" />
          <div v-if="gradeStripes.semiflushStripe4 != 'none'" class="stripe" :style="{'background-color': gradeStripes.semiflushStripe4}" />
        </div>
      </div>
    </div>
    <div v-if="type === 'SemiPremiumTubeAndCoupling'">
      <div class="pipe">
        <div class="inner" :style="gradeStripes.sptncField != 'none' ? 'background-color:' + gradeStripes.sptncField : ''">
          <div v-if="gradeStripes.sptncCouplingStripe1 != 'none'" class="stripe" :style="{'background-color': gradeStripes.sptncCouplingStripe1}" />
          <div v-if="gradeStripes.sptncCouplingStripe2 != 'none'" class="stripe" :style="{'background-color': gradeStripes.sptncCouplingStripe2}" />
          <div v-if="gradeStripes.sptncCouplingStripe3 != 'none'" class="stripe" :style="{'background-color': gradeStripes.sptncCouplingStripe3}" />
          <div v-if="specialCoupling" class="stripe" :style="{'background-color': 'black'}" />
        </div>
        <div class="inner tube">
          <div v-if="gradeStripes.sptncTubeStripe1 != 'none'" class="stripe" :style="{'background-color': gradeStripes.sptncTubeStripe1}" />
          <div v-if="gradeStripes.sptncTubeStripe2 != 'none'" class="stripe" :style="{'background-color': gradeStripes.sptncTubeStripe2}" />
          <div v-if="gradeStripes.sptncTubeStripe3 != 'none'" class="stripe" :style="{'background-color': gradeStripes.sptncTubeStripe3}" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      required: true,
      type: String
    },
    gradeStripes: {
      required: true,
      type: Object
    },
    label: {
      required: false,
      type: String
    },
    datasheet: {
      required: false,
      type: Boolean
    },
    specialCoupling: {
      required: false,
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
$lt-grey: #D8D8D8;

.flex {
  display: flex;
}

.pipe {
  @extend .flex;
  height: 100px;
  width: 100%;
  .inner {
    @extend .flex;
    flex: 1;
    justify-content: center;
    background: $lt-grey;
    border: solid 1px #000;
    .stripe {
      width: 10px;
      background: #333;
      margin: 0 2%;
    }
  }
  .tube {
    background: $lt-grey;
    margin: 10px auto 10px -1px;
  }

  &#semiflush {
    .inner {
      &.left {
        border-right: none;
      }
      &.center {
        background: none;
        border: none;
        margin: 0 -1px;
        flex: 0;
        min-width: 50px;
        svg {
          width: 100%;
          fill: $lt-grey;
        }
      }
      &.right {
        border-left: none;
        margin: 10px 0;
      }
    }
  }
}

</style>
